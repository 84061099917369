import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ImportButton } from "react-admin-import-csv";
import { CreateButton, ExportButton } from "ra-ui-materialui";
import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useListContext,
   TopToolbar,
  Button,
  sanitizeListRestProps,
} from 'react-admin';


const ListActions = (props) => {
  const {  
    className, 
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const config = {
    logging: true,
    validateRow: async (row) => {
      if (row.id) {
        // throw new Error("AAAA");
      }
    },
	preCommitCallback: (action, values) => {
	  const parsedValues: any[] = [];
	  console.log('Action', {action});
      console.log('values', {values});
	  for (var i = 0; i < values.length; i++) 
	  {
		if(values[i].id == null)
		{
		   values[i].id = uuidv4();
		}
		parsedValues.push(values[i]);
	  }
	  return parsedValues;
    },
    postCommitCallback: reportItems => {
      console.log('reportItems', {reportItems});
    },
    parseConfig: {
      delimiter: ";",	
      dynamicTyping: true
    }
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <ImportButton {...props} {...config} />
    </TopToolbar>
  );
};
export default ListActions;