import React from "react";
import './DropLink.css';
import retrieve from './youtube.get-video-info'
//import { getLinkPreview, getPreviewFromContent } from 'link-preview-js';
import getYouTubeID from 'get-youtube-id';
const DropLink = (props) => {

    const fetchVideoDetailsById = async (url) => {
      try {
      var videId = getYouTubeID(url);
      console.log(videId)
      
      retrieve(videId, function(err, res) {
        if (err) {
          return err
        } else if (res.status === 'fail') {
          return res.status
        } else {
          const playerResponse = JSON.parse(res.player_response)
          //const captions = playerResponse.captions.playerCaptionsTracklistRenderer.captionTracks.filter(c => c.kind !== 'asr')
          //const streams = playerResponse.streamingData.formats
          const details = playerResponse.videoDetails
          console.log(details.videoId)
          console.log(details.title)
          console.log(details.shortDescription)
          console.log(details.thumbnail.thumbnails[0].url)
          console.log(details.author)
          console.log(details.channelId)
          //...
          let result = {
            videoId: details.videoId,
            title: details.title,
            description: details.shortDescription,
            thumbnail: details.thumbnail.thumbnails[0].url,
            author: details.author,
            channelId: details.channelId            
          };
          console.log(result)
          return result;
          //...
        }
      })
    } catch (err) {
      //return handleError(err)
      console.log(err)
    }
  }
  React.useEffect(() => {

    window.addEventListener("dragover", function(event) {
      console.log("dragover");
      event.preventDefault();
    });

    window.addEventListener("drop", function(event) {
      console.log("drop");
      event.preventDefault();
      //if ( event.target.className == "droptarget" ) 
        //document.getElementById("demo").style.color = "";
        event.target.style.border = "";
        var data = event.dataTransfer.getData("Text");
        //event.target.appendChild(document.getElementById(data));
        console.log("... Drop: URI = " + data);
        fetchVideoDetailsById(data)
        //alert(data);
    });

  }, []);

  return (
    <div className='container'>
      <h1>Drop a Link Here...</h1>
      <div id="drop_zone">
        <p>Drag a link here...</p>
      </div>
    </div>
    
  );
};
export default DropLink;