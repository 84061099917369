import React from 'react';
import { Edit, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
import { AutocompleteInput, ImageField } from 'react-admin';

import {
   ReferenceField,
   TabbedShowLayout,
   Tab,
   SingleFieldList,
   ChipField,
   TopToolbar,
   ImageInput,
   ListButton,
   CloneButton,
   ShowButton,
   TabbedForm,
   FormTab,
   Toolbar,
   SaveButton,
   required,
   SelectInput,
   List,
   Show,
   RichTextField,
   SimpleShowLayout,
   Create,
   Datagrid,
   TextField,
   EditButton,
   DeleteButton,
   DeleteWithConfirmButton,
   BooleanInput,
   minLength
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { DateTimeInput } from 'react-admin';
import PostTitle from '../PostTitle';
import ListActions from '../ListActions';

export const FeedAuthorList = props => (
   <List {...props}  actions={<ListActions />}>
       <Datagrid rowClick="edit">
			<ReferenceField label="Feed" source="feed_id" reference="favreads_feeds">
				<TextField source="title.en" />
			</ReferenceField>
			<ReferenceField label="Author" source="author_id" reference="favreads_authors">
				<TextField source="name.en" />
			</ReferenceField>
           <ShowButton label="" />
		   <EditButton label="" />
		   <DeleteWithConfirmButton label="" redirect={false} />
       </Datagrid>
   </List>
);
const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton undoable={false} />
    </Toolbar>
);
const EditActions = ({ basePath, data, hasShow }) => (
    <TopToolbar>
        <CloneButton
            className="button-clone"
            basePath={basePath}
            record={data}
        />
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
);

export const FeedAuthorEdit = props => (
   <Edit actions={<EditActions />} {...props}>
	<SimpleForm>
			<ReferenceInput label="Feed" source="feed_id" reference="favreads_feeds">
				<AutocompleteInput optionText="title.en" />
			</ReferenceInput>
			<ReferenceInput label="Author" source="author_id" reference="favreads_authors">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
		</SimpleForm>
   </Edit>
);
const FeedAuthorTitle = ({ record }) => {
    return <span>Feed: {record ? `${record.name.en}` : ''}</span>;
};

const FeedAuthorShowActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} record={data} label="Back" />
    </TopToolbar>
);
export const FeedAuthorShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
		<ReferenceField label="Feed" source="feed_id" reference="favreads_feeds">
			<TextField source="title.en" />
		</ReferenceField>
		<ReferenceField label="Author" source="author_id" reference="favreads_authors">
			<TextField source="name.en" />
		</ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export const FeedAuthorCreate = props => (
   <Create {...props}>
		<SimpleForm>
			<ReferenceInput 
				label="Feed" 
				source="feed_id" 
				reference="favreads_feeds"  
				perPage={200}
				sort={{ field: 'publish_at', order: 'DESC' }}
			>
				<AutocompleteInput optionText="title.en" />
			</ReferenceInput>
			<ReferenceInput 
				label="Author" 
				source="author_id" 
				reference="favreads_authors" 
				perPage={200}
				sort={{ field: 'slug', order: 'ASC' }}
			>
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
		</SimpleForm>
   </Create>
);