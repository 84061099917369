import React from 'react';
import { Edit, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
import { AutocompleteInput, ImageField } from 'react-admin';

import {
   Filter,
   TopToolbar,
   ImageInput,
   CloneButton,
   ShowButton,
   TabbedForm,
   FormTab,
   Toolbar,
   SaveButton,
   required,
   List,
   Show,
   RichTextField,
   SimpleShowLayout,
   Create,
   Datagrid,
   TextField,
   EditButton,
   DeleteButton,
   DeleteWithConfirmButton,
   BooleanInput,
   SelectInput,
   BooleanField,
   DateField,
   minLength
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { DateTimeInput } from 'react-admin';
import PostTitle from '../PostTitle';
import ListActions from '../ListActions';

const QuoteFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search by Content" source="body.en" alwaysOn />
        <SelectInput initialValue="quote_random" source="format" label="Quote View Format" choices={[
				{ id: 'quote_random', name: 'Quote Random View Layout' },
				{ id: 'quote_default_alt', name: 'Alternative default Quote View Layout' },
				{ id: 'quote_default', name: 'Default Quote View Layout' },			
			]} />
        <ReferenceInput label="Author" source="authorId" reference="favreads_authors" allowEmpty>
            <SelectInput optionText="name.en" />
        </ReferenceInput>
        <ReferenceInput label="Source" source="sourceId" reference="favreads_quote_sources" allowEmpty>
            <SelectInput optionText="name.en" />
        </ReferenceInput>
        <ReferenceInput label="Language" source="languageId" reference="favreads_languages" allowEmpty>
            <SelectInput optionText="name.en" />
        </ReferenceInput>        
        <BooleanInput source="is_active" label="Active?" allowEmpty />
    </Filter>
);

export const QuoteList = props => (
	<List filters={<QuoteFilter />} actions={<ListActions />} {...props}>
       <Datagrid rowClick="edit">
	  		<ImageField source="thumbnail" title="title"  label="Thumbnail"/>
            <RichTextField source="body.en"  label="Body"/>
            <TextField source="format" label="Quote View Format" />
            <ImageField source="icon_header" title="title"  label="Header Icon"/>
            <ImageField source="icon_footer" title="title"  label="Footer Icon"/>
            <BooleanField source="is_active" label="Is Active?" />
            <DateField source="created_at" />
            <ShowButton label="" />
		    <EditButton label="" />
		    <DeleteWithConfirmButton label="" redirect={false} />
       </Datagrid>
   </List>
);
const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton undoable={false} />
    </Toolbar>
);
const EditActions = ({ basePath, data, hasShow }) => (
    <TopToolbar>
        <CloneButton
            className="button-clone"
            basePath={basePath}
            record={data}
        />
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
);
export const QuoteEdit = props => (
   <Edit actions={<EditActions />} {...props}>
      <SimpleForm>
            <TextInput disabled source="id"/>
            <RichTextInput source="body.en" label="Body-English" />
  		    <RichTextInput source="body.hi" label="Body-Hindi" />
            <TextInput source="thumbnail" fullWidth={true}/>
			<TextInput source="backdrop" fullWidth={true}/>
            <TextInput source="icon_header" label="Header Icon" fullWidth={true} />
            <TextInput source="icon_footer" label="Footer Icon" fullWidth={true} />
            <SelectInput initialValue="quote_random" source="format" label="Quote View Format" choices={[
				{ id: 'quote_random', name: 'Quote Random View Layout' },
				{ id: 'quote_default_alt', name: 'Alternative default Quote View Layout' },
				{ id: 'quote_default', name: 'Default Quote View Layout' },			
			]} />
            <ReferenceInput label="Language" source="languageId" reference="favreads_languages">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
            <ReferenceInput label="Author" source="authorId" reference="favreads_authors">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
            <ReferenceInput label="Source" source="sourceId" reference="favreads_quote_sources">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
			<BooleanInput source="is_active"/>
	  </SimpleForm>
   </Edit>
);

export const QuoteShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <RichTextField source="body.en" />
	  <ImageField source="thumbnail" title="title" />
    </SimpleShowLayout>
  </Show>
);

export const QuoteCreate = props => (
   <Create {...props}>
	<SimpleForm>
            <TextInput disabled source="id"/>
            <RichTextInput source="body.en" label="Body-English" />
  		    <RichTextInput source="body.hi" label="Body-Hindi" />
			<TextInput source="thumbnail" fullWidth={true}/>
			<TextInput source="backdrop" fullWidth={true}/>
            <TextInput source="icon_header" label="Header Icon" fullWidth={true} />
            <TextInput source="icon_footer" label="Footer Icon" fullWidth={true} />
            <SelectInput initialValue="quote_random" source="format" label="Quote View Format" choices={[
				{ id: 'quote_random', name: 'Quote Random View Layout' },
				{ id: 'quote_default_alt', name: 'Alternative default Quote View Layout' },
				{ id: 'quote_default', name: 'Default Quote View Layout' },			
			]} />
            <ReferenceInput label="Language" source="languageId" reference="favreads_languages">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
            <ReferenceInput label="Author" source="authorId" reference="favreads_authors">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
            <ReferenceInput label="Source" source="sourceId" reference="favreads_quote_sources">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>            
			<BooleanInput source="is_active"/>
	  </SimpleForm>
   </Create>
);