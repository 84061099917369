import React from 'react';
import { Edit, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
import { AutocompleteInput, ImageField } from 'react-admin';

import {
   Filter,
   TopToolbar,
   ImageInput,
   CloneButton,
   ShowButton,
   TabbedForm,
   FormTab,
   Toolbar,
   SaveButton,
   required,
   List,
   Show,
   RichTextField,
   SimpleShowLayout,
   Create,
   Datagrid,
   TextField,
   EditButton,
   DeleteButton,
   DeleteWithConfirmButton,
   BooleanInput,
   SelectInput,
   BooleanField,
   DateField,
   minLength
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { DateTimeInput } from 'react-admin';
import PostTitle from '../PostTitle';
import ListActions from '../ListActions';

const SubhashitFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search by Content" source="body" alwaysOn />
        <ReferenceInput label="Author" source="authorId" reference="favreads_authors" allowEmpty>
            <SelectInput optionText="name.en" />
        </ReferenceInput>
        <ReferenceInput label="Source" source="sourceId" reference="favreads_quote_sources" allowEmpty>
            <SelectInput optionText="name.en" />
        </ReferenceInput>
        <ReferenceInput label="Language" source="languageId" reference="favreads_languages" allowEmpty>
            <SelectInput optionText="name.en" />
        </ReferenceInput>        
        <BooleanInput source="is_active" label="Active?" allowEmpty />
    </Filter>
);

export const SubhashitList = props => (
	<List filters={<SubhashitFilter />} actions={<ListActions />} {...props}>
       <Datagrid rowClick="edit">
	  		<ImageField source="thumbnail" title="title"  label="Thumbnail"/>
            <RichTextField source="body"  label="Body"/>
		    <RichTextField source="short_description.en" label="Short Description" />
            <BooleanField source="is_active" label="Is Active?" />
            <DateField source="created_at" />
           <ShowButton label="" />
		   <EditButton label="" />
		   <DeleteWithConfirmButton label="" redirect={false} />
       </Datagrid>
   </List>
);
const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton undoable={false} />
    </Toolbar>
);
const EditActions = ({ basePath, data, hasShow }) => (
    <TopToolbar>
        <CloneButton
            className="button-clone"
            basePath={basePath}
            record={data}
        />
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
);
export const SubhashitEdit = props => (
   <Edit actions={<EditActions />} {...props}>
      <SimpleForm>
            <TextInput disabled source="id"/>
			<RichTextInput source="body" label="Body" />
            <RichTextInput source="short_description.en" label="Summary-English" />
  		    <RichTextInput source="short_description.hi" label="Summary-Hindi" />
            <TextInput source="thumbnail" fullWidth={true}/>
			<TextInput source="backdrop" fullWidth={true}/>
			<TextInput source="icon_header" label="Header Icon" fullWidth={true} />
            <TextInput source="icon_footer" label="Footer Icon"  fullWidth={true} />
            <TextInput source="icon_divider" label="Divider Icon" fullWidth={true} />
            <ReferenceInput label="Language" source="languageId" reference="favreads_languages">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
            <ReferenceInput label="Author" source="authorId" reference="favreads_authors">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
            <ReferenceInput label="Source" source="sourceId" reference="favreads_quote_sources">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
			<BooleanInput source="is_active"/>
	  </SimpleForm>
   </Edit>
);

export const SubhashitShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <RichTextField source="body" />
      <RichTextField source="short_description.en" />
	  <ImageField source="thumbnail" title="title" />
    </SimpleShowLayout>
  </Show>
);

export const SubhashitCreate = props => (
   <Create {...props}>
	<SimpleForm>
            <TextInput disabled source="id"/>
			<RichTextInput source="body" label="Body" />
            <RichTextInput source="short_description.en" label="Summary-English" />
  		    <RichTextInput source="short_description.hi" label="Summary-Hindi" />
            <TextInput source="thumbnail" fullWidth={true}/>
			<TextInput source="backdrop" fullWidth={true}/>
			<TextInput source="icon_header" label="Header Icon"  fullWidth={true}/>
            <TextInput source="icon_footer" label="Footer Icon"  fullWidth={true}/>
            <TextInput source="icon_divider" label="Divider Icon"  fullWidth={true}/>
            <ReferenceInput label="Language" source="languageId" reference="favreads_languages">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
            <ReferenceInput label="Author" source="authorId" reference="favreads_authors">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
            <ReferenceInput label="Source" source="sourceId" reference="favreads_quote_sources">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>            
			<BooleanInput source="is_active"/>
	  </SimpleForm>
   </Create>
);