import React, { Component } from 'react';
import { fetchUtils, Admin, Resource } from "react-admin";
import {
  FirebaseAuthProvider,
} from "react-admin-firebase";
import firebase from 'firebase/app';
import 'firebase/auth';
// Icon
import BookIcon from "@material-ui/icons/Book";
// import EventIcon from "@material-ui/icons/Event";
import FeedAddIcon from '@material-ui/icons/PostAdd';
import GroupIcon from '@material-ui/icons/Group';
import ApolloClient from 'apollo-boost';
// Language
//import polyglotI18nProvider from "ra-i18n-polyglot";
//import vietnameseMessages from "./i18n/vi";
import fakeDataProvider from "ra-data-fakerest";
import gql from 'graphql-tag';
//import buildDataProvider, { buildFields } from 'ra-data-hasura-graphql';
import buildHasuraProvider from 'ra-data-hasura-graphql';
// Components
//import products from "./components/products";
//import types from "./components/category";
import {UserList,UserShow } from "./components/users/users.js";
import {FeedList, FeedShow, FeedCreate, FeedEdit} from "./components/feeds/feeds.js";
import {FeedAuthorList, FeedAuthorShow, FeedAuthorCreate, FeedAuthorEdit} from "./components/feeds/feedauthor.js";
import {AuthorList, AuthorShow, AuthorCreate, AuthorEdit} from "./components/authors/authors.js";
import {PublisherList, PublisherShow, PublisherCreate, PublisherEdit} from "./components/publishers/publishers.js";
import {GenreList, GenreShow, GenreCreate, GenreEdit} from "./components/genres/genres.js";
import {SubjectList, SubjectShow, SubjectCreate, SubjectEdit} from "./components/subjects/subjects.js";
import {CategoryList, CategoryShow, CategoryCreate, CategoryEdit} from "./components/categories/categories.js";
import {AwardList, AwardShow, AwardCreate, AwardEdit} from "./components/awards/awards.js";
import {ChannelList, ChannelShow, ChannelCreate, ChannelEdit} from "./components/channels/channels.js";
import {BookList, BookShow, BookCreate, BookEdit} from "./components/books/books.js";
import {LanguageList, LanguageShow, LanguageCreate, LanguageEdit} from "./components/languages/languages.js";
import {CollectionList, CollectionShow, CollectionCreate, CollectionEdit} from "./components/collections/collections.js";
import {DohaList, DohaShow, DohaCreate, DohaEdit} from "./components/dohas/dohas.js";
import {QuoteList, QuoteShow, QuoteCreate, QuoteEdit} from "./components/quotes/quotes.js";
import {SubhashitList, SubhashitShow, SubhashitCreate, SubhashitEdit} from "./components/subhashits/subhashits.js";
import {WordOfDayList, WordOfDayShow, WordOfDayCreate, WordOfDayEdit} from "./components/wordofdays/wordofdays.js";
import {AudioList, AudioShow, AudioCreate, AudioEdit} from "./components/audios/audios.js";
import {VideoList, VideoShow, VideoCreate, VideoEdit} from "./components/videos/videos.js";
import CustomLoginPage from './CustomLoginPage';
//import { PostList, PostEdit, PostCreate, PostShow } from "./posts.js";
//import { BookList, BookEdit, BookCreate, BookShow } from "./books.js";
//connect the data provider to the REST endpoint
import { firebaseConfig as config, options } from "./FIREBASE_CONFIG";
import hasuraDataProvider from "ra-data-hasura";
import { Layout } from "./layout";
import { Dashboard } from "./components/dashboard";
import CalendarPage from "./calendar";
import { Route } from 'react-router-dom';
import DropLink from "./DropLink.js";


const dataProviderFake = fakeDataProvider({
    posts: [
      { id: "f8cf2027-a3aa-401e-8fb6-c33dc0011000", title: {"en":"Foodbar", "hi":"Foo-hindi"}, thumbnail: "https://1772133009.rsc.cdn77.org/images/books/gunaho_ka_devta.jpg",page_count: 100, slug: "foobar",isbn:"8126312939", isbn13:"9788126312931", backdrop:"https://i.imgur.com/t3j6eqx.jpg", backcover:"https://i.imgur.com/t3j6eqx.jpg",short_description: {"en":"Foodbar", "hi":"Foo-hindi"} },
      { id: "f8cf2027-a3aa-401e-8fb6-c33dc0011001", title: {"en":"Another", "hi":"Another-hindi"}, thumbnail: "https://1772133009.rsc.cdn77.org/images/books/gunaho_ka_devta.jpg",page_count: 100,slug: "another",isbn:"8126312939", isbn13:"9788126312931", backdrop:"https://i.imgur.com/t3j6eqx.jpg", backcover:"https://i.imgur.com/t3j6eqx.jpg",short_description: {"en":"Foodbar", "hi":"Foo-hindi"} },
      { id: "f8cf2027-a3aa-401e-8fb6-c33dc0011002", title: {"en":"Thing", "hi":"Thing-hindi"}, thumbnail: "https://1772133009.rsc.cdn77.org/images/books/gunaho_ka_devta.jpg",page_count: 100,slug: "thing",isbn:"8126312939", isbn13:"9788126312931", backdrop:"https://i.imgur.com/t3j6eqx.jpg", backcover:"https://i.imgur.com/t3j6eqx.jpg",short_description: {"en":"Foodbar", "hi":"Foo-hindi"} },
      { id: "f8cf2027-a3aa-401e-8fb6-c33dc0011003", title: {"en":"Hello World", "hi":"Hello World-hindi"}, thumbnail: "https://1772133009.rsc.cdn77.org/images/books/gunaho_ka_devta.jpg",page_count: 100,slug: "hello-world",isbn:"8126312939", isbn13:"9788126312931", backdrop:"https://i.imgur.com/t3j6eqx.jpg", backcover:"https://i.imgur.com/t3j6eqx.jpg",short_description: {"en":"Foodbar", "hi":"Foo-hindi"}},
    ],
  });

const firebaseAuthProvider = FirebaseAuthProvider(config, options);


// Create a client for Hasura with the right headers
const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here
  options.headers.set('X-Hasura-Admin-Secret', process.env.REACT_APP_ADMIN_SECRET_CODE);
  
  return fetchUtils.fetchJson(url, options);
};

const client = new ApolloClient({
  uri: process.env.REACT_APP_ADMIN_BASE_URL + "/v1/graphql",
  request: operation => {
    const token = process.env.REACT_APP_ADMIN_SECRET_CODE;
    operation.setContext({
      headers: {
        "x-hasura-admin-secret": token
      }
    });
  }
});



//const dataProvider = hasuraDataProvider(process.env.REACT_APP_ADMIN_BASE_URL, httpClient);

class App extends Component {
  constructor() {
    super();
    this.state = { dataProvider: null };
  }
  componentDidMount() {
    buildHasuraProvider({client: client})
          .then(dataProvider => this.setState({ dataProvider }));
  }
  render() {
    const { dataProvider } = this.state;
 
    if (!dataProvider) {
        return <div>Loading</div>;
    }

    return (
      <Admin
      //loginPage={CustomLoginPage} 
        dataProvider={dataProvider}
        authProvider={firebaseAuthProvider}
        //i18nProvider={i18nProvider}
        layout={Layout}
        dashboard={Dashboard}
      customRoutes={[
      <Route
        exact
        path="/calendar"
        component={props => <CalendarPage {...props} />}
        noLayout
      />,
      <Route
        exact
        path="/droplink"
        component={props => <DropLink {...props} />}
        noLayout
      />
    ]}
      >
        <Resource options={{ label: 'Users' }} name="favreads_users" list={UserList} show={UserShow} icon={GroupIcon}/>
        <Resource options={{ label: 'Feeds' }}  name="favreads_feeds" list={FeedList} show={FeedShow} create={FeedCreate} edit={FeedEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Authors' }} name="favreads_authors" list={AuthorList} show={AuthorShow} create={AuthorCreate} edit={AuthorEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Publishers' }} name="favreads_publishers" list={PublisherList} show={PublisherShow} create={PublisherCreate} edit={PublisherEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Genres' }} name="favreads_genres" list={GenreList} show={GenreShow} create={GenreCreate} edit={GenreEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Categories' }} name="favreads_categories" list={CategoryList} show={CategoryShow} create={CategoryCreate} edit={CategoryEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Subjects' }} name="favreads_subjects" list={SubjectList} show={SubjectShow} create={SubjectCreate} edit={SubjectEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Languages' }} name="favreads_languages" list={LanguageList} show={LanguageShow} create={LanguageCreate} edit={LanguageEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Awards' }} name="favreads_awards" list={AwardList} show={AwardShow} create={AwardCreate} edit={AwardEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Books' }} name="favreads_books" list={BookList} show={BookShow} create={BookCreate} edit={BookEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Channels' }} name="favreads_channels" list={ChannelList} show={ChannelShow} create={ChannelCreate} edit={ChannelEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Collections' }} name="favreads_collections" list={CollectionList} show={CollectionShow} create={CollectionCreate} edit={CollectionEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Dohas' }} name="favreads_dohas" list={DohaList} show={DohaShow} create={DohaCreate} edit={DohaEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Quotes' }} name="favreads_quotes" list={QuoteList} show={QuoteShow} create={QuoteCreate} edit={QuoteEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Subhashits' }} name="favreads_subhashits" list={SubhashitList} show={SubhashitShow} create={SubhashitCreate} edit={SubhashitEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'WordofDays' }} name="favreads_wordofdays" list={WordOfDayList} show={WordOfDayShow} create={WordOfDayCreate} edit={WordOfDayEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Audios' }} name="favreads_audios" list={AudioList} show={AudioShow} create={AudioCreate} edit={AudioEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Videos' }} name="favreads_videos" list={VideoList} show={VideoShow} create={VideoCreate} edit={VideoEdit} icon={FeedAddIcon}/>
        <Resource options={{ label: 'Feed-Authors' }} name="favreads_feed_author" list={FeedAuthorList} show={FeedAuthorShow} create={FeedAuthorCreate} edit={FeedAuthorEdit} icon={FeedAddIcon}/>
       </Admin>
    );
  }
}

export default App;
