import React from 'react';
import { Edit, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
import { AutocompleteInput, ImageField } from 'react-admin';

import {
   Filter,
   TopToolbar,
   ImageInput,
   CloneButton,
   ShowButton,
   TabbedForm,
   FormTab,
   Toolbar,
   SaveButton,
   required,
   List,
   Show,
   RichTextField,
   SimpleShowLayout,
   Create,
   Datagrid,
   TextField,
   EditButton,
   DeleteButton,
   DeleteWithConfirmButton,
   BooleanInput,
   SelectInput,
   BooleanField,
   DateField,
   NumberInput,
   minLength
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { DateTimeInput } from 'react-admin';
import PostTitle from '../PostTitle';
import ListActions from '../ListActions';

const BookFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search by Title" source="title.en" alwaysOn />
        <ReferenceInput label="Author" source="id" reference="favreads_authors" allowEmpty>
            <SelectInput optionText="name.en" />
        </ReferenceInput>
        <BooleanInput source="is_active" label="Active?" allowEmpty />
    </Filter>
);


export const BookList = props => (
	<List filters={<BookFilter />} actions={<ListActions />} {...props}>
       <Datagrid rowClick="edit">
		   <ImageField source="thumbnail" title="title"  label="Thumbnail"/>
           <TextField source="title.en"  label="Title" />
		   <BooleanField source="is_active" label="Is Active?" />
		   <RichTextField source="short_description.en" label="Description-English" />
           <ShowButton label="" />
		   <EditButton label="" />
		   <DeleteWithConfirmButton label="" redirect={false} />
       </Datagrid>
   </List>
);
const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton undoable={false} />
    </Toolbar>
);
const EditActions = ({ basePath, data, hasShow }) => (
    <TopToolbar>
        <CloneButton
            className="button-clone"
            basePath={basePath}
            record={data}
        />
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
);
export const BookEdit = props => (
   <Edit actions={<EditActions />} {...props}>
      <TabbedForm warnWhenUnsavedChanges>
	    <FormTab label="Summary">
            <TextInput disabled source="id"/>
			<TextInput source="thumbnail" fullWidth={true}/>
		    <TextInput source="backcover" fullWidth={true}/>
			<TextInput source="backdrop" fullWidth={true}/>
			<TextInput source="slug" fullWidth={true}/>
			<TextInput source="link" fullWidth={true}/>
			<NumberInput source="page_count"/>
			<TextInput source="isbn"/>
			<TextInput source="isbn13"/>
			<BooleanInput source="is_active"/>
		</FormTab>
		<FormTab label="Translations">
			<TextInput
				source="title.en"
				label="Title-English"
				fullWidth={true}
				validate={required()}
			/>
			<TextInput
				source="title.hi"
				label="Title-Hindi"
				fullWidth={true}
				validate={required()}
			/>
			<RichTextInput source="short_description.en" label="Summary-English" />
  		    <RichTextInput source="short_description.hi" label="Summary-Hindi" />
			<RichTextInput source="description.en" label="Description-English" />
  		    <RichTextInput source="description .hi" label="Description-Hindi" />
 		</FormTab>
	  </TabbedForm>
   </Edit>
);

export const BookShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title.en" />
	  <ImageField source="thumbnail" title="title" />
      <RichTextField source="short_description.en" />
    </SimpleShowLayout>
  </Show>
);

export const BookCreate = props => (
   <Create {...props}>
	<TabbedForm warnWhenUnsavedChanges>
	    <FormTab label="Summary">
            <TextInput disabled source="id"/>
			<TextInput source="thumbnail" fullWidth={true}/>
		    <TextInput source="backcover" fullWidth={true}/>
			<TextInput source="backdrop" fullWidth={true}/>
			<TextInput source="slug" fullWidth={true}/>
			<TextInput source="link" fullWidth={true}/>
			<NumberInput source="page_count"/>
			<TextInput source="isbn"/>
			<TextInput source="isbn13"/>
			<BooleanInput source="is_active"/>
		</FormTab>
		<FormTab label="Translations">
			<TextInput
				source="title.en"
				label="Title-English"
				fullWidth={true}
				validate={required()}
			/>
			<TextInput
				source="title.hi"
				label="Title-Hindi"
				fullWidth={true}
				validate={required()}
			/>
			<RichTextInput source="short_description.en" label="Summary-English" />
  		    <RichTextInput source="short_description.hi" label="Summary-Hindi" />
			<RichTextInput source="description.en" label="Description-English" />
  		    <RichTextInput source="description.hi" label="Description-Hindi" />
 		</FormTab>
	  </TabbedForm>
   </Create>
);