import React from 'react';
import { Edit, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
import { AutocompleteInput, ImageField } from 'react-admin';

import {
   Filter,
   ReferenceField,
   TabbedShowLayout,
   Tab,
   SingleFieldList,
   ChipField,
   TopToolbar,
   ImageInput,
   ListButton,
   CloneButton,
   ShowButton,
   TabbedForm,
   FormTab,
   Toolbar,
   SaveButton,
   required,
   SelectInput,
   List,
   Show,
   RichTextField,
   SimpleShowLayout,
   Create,
   Datagrid,
   TextField,
   EditButton,
   DeleteButton,
   DeleteWithConfirmButton,
   BooleanInput,
   BooleanField,
   DateField,
   minLength
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { DateTimeInput } from 'react-admin';
import PostTitle from '../PostTitle';
import ListActions from '../ListActions';

const FeedFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search by Title" source="title.en" alwaysOn />
        <ReferenceInput label="Channel" source="channel_id" reference="favreads_channels" allowEmpty>
            <SelectInput optionText="name.en" />
        </ReferenceInput>
        <BooleanInput source="is_published" label="Published?" allowEmpty />
		<SelectInput source="status" label="Publicaton Status" choices={[
				{ id: 'published', name: 'Published' },
				{ id: 'draft', name: 'Draft' },
				{ id: 'scheduled', name: 'Scheduled' },
			]} />
		<SelectInput source="format_type" label="Content Format" choices={[
				{ id: 'standard', name: 'Standard' },
				{ id: 'audio', name: 'Audio' },
				{ id: 'video', name: 'Video' },
				{ id: 'image', name: 'Single Image View' },
				{ id: 'quote', name: 'Quote' },
				{ id: 'gallery', name: 'Photo Gallery' },
				{ id: 'link', name: 'Web Link' },
				{ id: 'socialstatus', name: 'Social Status' },
				{ id: 'slider', name: 'Multiple Slide View' },
				{ id: 'richtextlong', name: 'RichText Long' },
				{ id: 'richtextshort', name: 'RichText Short' },
				{ id: 'paperclip', name: 'PaperClip from Newspaper' },
				{ id: 'paperclip_landscape', name: 'PaperClip from Newspaper-Landscape' },
				{ id: 'flipcard', name: 'Flip Card' },
				{ id: 'twitter_status', name: 'Twitter Status' },
				{ id: 'facebook_post', name: 'Facebook Post' },
				{ id: 'quote_random', name: 'Quote Random View Layout' },
				{ id: 'quote_default_alt', name: 'Alternative default Quote View Layout' },
				{ id: 'quote_default', name: 'Default Quote View Layout' },
				{ id: 'doha', name: 'Doha Kabir Tulsidas ji etc' },
				{ id: 'subhashit', name: 'Sanskrit Shlok etc' },
				{ id: 'wordofday', name: 'Word of the Day' },
			]} />
    </Filter>
);

export const FeedList = (props) => (
   <List filters={<FeedFilter />} actions={<ListActions />} {...props} >
       <Datagrid rowClick="edit">
	       <ImageField source="thumbnail" title="title"  label="Thumbnail"/>
				<TextField source="title.en" />
		    <RichTextField source="short_description.en" label="Description-English" />
			<ReferenceField label="Channel" source="channel_id" reference="favreads_channels">
				<TextField source="name.en" />
			</ReferenceField>
            <BooleanField source="is_published" label="Is Published?" />
            <DateField source="created_at" />
           <ShowButton label="" />
		   <EditButton label="" />
		   <DeleteWithConfirmButton label="" redirect={false} />
       </Datagrid>
   </List>
);

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton undoable={false} />
    </Toolbar>
);
const EditActions = ({ basePath, data, hasShow }) => (
    <TopToolbar>
        <CloneButton
            className="button-clone"
            basePath={basePath}
            record={data}
        />
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
);
export const FeedEdit = props => (
   <Edit title={<PostTitle />} actions={<EditActions />} {...props}>
      <TabbedForm warnWhenUnsavedChanges>
	    <FormTab label="Summary">
           <TextInput disabled source="id"/>
			<TextInput source="thumbnail" fullWidth={true}/>
			<TextInput source="slug" fullWidth={true}/>
			<TextInput source="link" fullWidth={true}/>
			<TextInput source="iframe" multiline={true} fullWidth={true}/>
			<SelectInput source="status" label="Publicaton Status" choices={[
				{ id: 'published', name: 'Published' },
				{ id: 'draft', name: 'Draft' },
				{ id: 'scheduled', name: 'Scheduled' },
			]} />
			<SelectInput source="format_type" label="Content Format" choices={[
				{ id: 'standard', name: 'Standard' },
				{ id: 'audio', name: 'Audio' },
				{ id: 'video', name: 'Video' },
				{ id: 'image', name: 'Single Image View' },
				{ id: 'quote', name: 'Quote' },
				{ id: 'gallery', name: 'Photo Gallery' },
				{ id: 'link', name: 'Web Link' },
				{ id: 'socialstatus', name: 'Social Status' },
				{ id: 'slider', name: 'Multiple Slide View' },
				{ id: 'richtextlong', name: 'RichText Long' },
				{ id: 'richtextshort', name: 'RichText Short' },
				{ id: 'paperclip', name: 'PaperClip from Newspaper' },
				{ id: 'paperclip_landscape', name: 'PaperClip from Newspaper-Landscape' },
				{ id: 'flipcard', name: 'Flip Card' },
				{ id: 'twitter_status', name: 'Twitter Status' },
				{ id: 'facebook_post', name: 'Facebook Post' },
				{ id: 'quote_random', name: 'Quote Random View Layout' },
				{ id: 'quote_default_alt', name: 'Alternative default Quote View Layout' },
				{ id: 'quote_default', name: 'Default Quote View Layout' },
				{ id: 'doha', name: 'Doha Kabir Tulsidas ji etc' },
				{ id: 'subhashit', name: 'Sanskrit Shlok etc' },
				{ id: 'wordofday', name: 'Word of the Day' },
			]} />
			<ReferenceInput label="Language" source="language_id" reference="favreads_languages">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
			<ReferenceInput label="Channel" source="channel_id" reference="favreads_channels">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
			<DateTimeInput source="publish_at" />
		</FormTab>
		<FormTab label="Translations">
			<TextInput
				source="title.en"
				label="Title-English"
				fullWidth={true}
				validate={required()}
			/>
			<TextInput
				source="title.hi"
				label="Title-Hindi"
				fullWidth={true}
				validate={required()}
			/>
			<RichTextInput source="short_description.en" label="Short Description" />
  		    <RichTextInput source="short_description.hi" label="Short Description-Hindi" />
			<RichTextInput source="description.en" label="Description-English" />
  		    <RichTextInput source="description .hi" label="Description-Hindi" />
 		</FormTab>
	  </TabbedForm>
   </Edit>
);
const FeedTitle = ({ record }) => {
    return <span>Feed: {record ? `${record.name}` : ''}</span>;
};

const FeedShowActions = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} record={data} label="Back" />
    </TopToolbar>
);
export const FeedShow = (props) => (
  <Show title={<FeedTitle />} actions={<FeedShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
      <TextField source="id" />
		  <ImageField source="thumbnail" title="title" />
      </Tab>
	  <Tab label="Translations">
		  <TextField source="id" />
		  <TextField source="title.en" label="Title-English"/>
		  <TextField source="title.hi" label="Title-Hindi"/>
		  <RichTextField source="short_description.en" label="Short Desc"/>
		  <RichTextField source="short_description.hi" label="Short Desc"/>
		  <RichTextField source="description.en" label="Desc"/>
		  <RichTextField source="description.hi" label="Desc"/>
      </Tab>
	  <Tab label="Relationships">
		<ReferenceField label="Language" source="language_id" reference="favreads_languages">
			<TextField source="name.en" />
		</ReferenceField>
		<ReferenceField label="Channel" source="channel_id" reference="favreads_channels">
			<TextField source="name.en" />
		</ReferenceField>
	  </Tab>
    </TabbedShowLayout>
  </Show>
);

export const FeedCreate = props => (
   <Create {...props}>
	<TabbedForm warnWhenUnsavedChanges>
	    <FormTab label="Summary">
           <TextInput disabled source="id"/>
			<TextInput initialValue="
https://via.placeholder.com/150/09f/fff.png" source="thumbnail" fullWidth={true}/>
			<TextInput source="slug" fullWidth={true}/>
			<TextInput source="link" fullWidth={true}/>
			<TextInput source="iframe" multiline={true} fullWidth={true}/>
			<SelectInput initialValue="draft" source="status" label="Publicaton Status" choices={[
				{ id: 'published', name: 'Published' },
				{ id: 'draft', name: 'Draft' },
				{ id: 'scheduled', name: 'Scheduled' },
			]} />
			<SelectInput initialValue="standard" source="format_type" label="Content Format" choices={[
				{ id: 'standard', name: 'Standard' },
				{ id: 'audio', name: 'Audio' },
				{ id: 'video', name: 'Video' },
				{ id: 'image', name: 'Single Image View' },
				{ id: 'quote', name: 'Quote' },
				{ id: 'gallery', name: 'Photo Gallery' },
				{ id: 'link', name: 'Web Link' },
				{ id: 'socialstatus', name: 'Social Status' },
				{ id: 'slider', name: 'Multiple Slide View' },
				{ id: 'richtextlong', name: 'RichText Long' },
				{ id: 'richtextshort', name: 'RichText Short' },
				{ id: 'paperclip', name: 'PaperClip from Newspaper' },
				{ id: 'paperclip_landscape', name: 'PaperClip from Newspaper-Landscape' },
				{ id: 'flipcard', name: 'Flip Card' },
				{ id: 'twitter_status', name: 'Twitter Status' },
				{ id: 'facebook_post', name: 'Facebook Post' },
				{ id: 'quote_random', name: 'Quote Random View Layout' },
				{ id: 'quote_default_alt', name: 'Alternative default Quote View Layout' },
				{ id: 'quote_default', name: 'Default Quote View Layout' },
				{ id: 'doha', name: 'Doha Kabir Tulsidas ji etc' },
				{ id: 'subhashit', name: 'Sanskrit Shlok etc' },
				{ id: 'wordofday', name: 'Word of the Day' },				
			]} />
			<ReferenceInput initialValue="b158a9c9-74a5-4682-b48f-63421c815165" label="Language" source="language_id" reference="favreads_languages">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
			<ReferenceInput initialValue="2f059c27-c87c-45ee-9656-d5a54d06cccd" label="Channel" source="channel_id" reference="favreads_channels">
				<AutocompleteInput optionText="name.en" />
			</ReferenceInput>
			<DateTimeInput source="publish_at" />
		</FormTab>
		<FormTab label="Translations">
			<TextInput
				source="title.en"
				label="Title-English"
				fullWidth={true}
				validate={required()}
			/>
			<TextInput
				source="title.hi"
				label="Title-Hindi"
				fullWidth={true}
				validate={required()}
			/>
			<RichTextInput source="short_description.en" label="Short Description" />
  		    <RichTextInput source="short_description.hi" label="Short Description-Hindi" />
			<RichTextInput source="description.en" label="Description-English" />
  		    <RichTextInput source="description.hi" label="Description-Hindi" />
 		</FormTab>
	  </TabbedForm>
   </Create>
);
