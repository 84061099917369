import React from 'react';
//import {Link} from 'react-router';
//import ReactDOM from 'react-dom';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios'
import './Calendar.css';
BigCalendar.momentLocalizer(moment);

class CalendarPage extends React.Component {
  constructor(props, context) {
    super(props, context);
	this.state = {
      cal_events: [
        //State is updated via componentDidMount
      ],
    }
  }

  componentDidMount() {
      const favreads_scheduled_feeds = `
        query getScheduledFeeds($lang:String!,$where: favreads_feeds_bool_exp){
          favreads_feeds(where: $where){
            id
            title(path:$lang)
            short_description(path:$lang)
            format_type
            publish_at
            status
          }
        }
      `;

      const query = favreads_scheduled_feeds;
      const variables = {
          lang: "en",
          "where": {
            "status": {"_eq": "scheduled"}
          }
       };
       axios.defaults.baseURL = process.env.REACT_APP_ADMIN_BASE_URL;
       axios.defaults.headers.post['X-Hasura-Admin-Secret'] = process.env.REACT_APP_ADMIN_SECRET_CODE;
       axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios({
          method: 'post',
          url: '/v1/graphql',
          data: JSON.stringify({ query, variables })
        }).then(response => {
          let feeds = response.data.data.favreads_feeds;
          for (let i = 0; i < feeds.length; i++) {
            feeds[i].start = new Date(feeds[i].publish_at)
            feeds[i].end = new Date(feeds[i].publish_at)
          }
          this.setState({
            cal_events:feeds
          })
          console.log(feeds);
        })
        .catch(function (error) {
          console.log(error);
        });
  }
  
  render() {
    const { cal_events } = this.state
    return (
      <div className="main-app">
		  <div className="Calendar">
			<header className="Calendar-header">
			  <h1 className="Calendar-title">React Calendar</h1>
			</header>
			<div style={{ height: 700 }}>
			  <BigCalendar
				events={cal_events}
				step={30}
				defaultView='week'
				views={['month','week','day']}
				defaultDate={new Date()}
			  />
			</div>
		  </div>
      </div>
    );
  }
}


export default CalendarPage;
