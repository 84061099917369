export const firebaseConfig = {
    apiKey: "AIzaSyD5tY2CzBYheB3RvgbnznHn5-665-JFZC0",
    authDomain: "inspired-access-227202.firebaseapp.com",
    databaseURL: "https://inspired-access-227202.firebaseio.com",
    projectId: "inspired-access-227202",
    storageBucket: "inspired-access-227202.appspot.com",
    messagingSenderId: "332327717374",
	appId: "1:332327717374:web:acadfa18f6a7a0be6098af",
};

export const options = {
  // Use a different root document to set your resource collections, by default it uses the root collections of firestore
  // rootRef: 'root-collection/some-doc',
  // Your own, previously initialized firebase app instance
  //     app: firebaseAppInstance,
  // Enable logging of react-admin-firebase
  logging: true,
  // Resources to watch for realtime updates, will implicitly watch all resources by default, if not set.
  //     watch: ['posts'],
  // Resources you explicitly dont want realtime updates for
  //     dontwatch: ['comments'],
  persistence: "session"
};
